<template>
  <div>
    <v-switch v-model="relative" flat label="Относительные величины"></v-switch>
    <StackedBarWithSum :d="getByAgeWithRelativePlot" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import StackedBarWithSum from '../../d3new/StackedBarWithSum.vue'

const template = {
  i: 0, // Индекс
  w: 550, // Ширина
  h: 350, // Высота
  aw: false, // Автоматическая ширина
  t: 'unknown', // Тип
  m: { l: 20, t: 20, r: 20, b: 20 }, // Границы
  d: [], // Данные
  o: {}, // Опции
}

export default {
  name: 'ByAgeContent',
  components: {
    StackedBarWithSum,
  },
  data() {
    return {
      tooltip: {},
    }
  },
  methods: {
    onTooltip(event) {
      this.tooltip = event
    },
    ...mapMutations({ setRelative: 'ly/SET_BY_AGE_RELATIVE' }),
  },
  computed: {
    ...mapGetters({
      getByAgeData: 'ly2/getByAgePlot',
      getRelative: 'ly/getByAgeRelative',
    }),
    relative: {
      get() {
        return this.getRelative
      },
      set(value) {
        this.setRelative(value)
      },
    },
    getByAgePlot() {
      return {
        ...template,
        d: [],
        o: {},
        ...this.getByAgeData,
        w: 1000,
        h: 2200,
      }
    },
    getByAgeWithRelativePlot() {
      const d = JSON.parse(JSON.stringify(this.getByAgePlot))
      if (d.d.length) {
        if (this.relative) {
          let data = d.d
          data = data.map(d => {
            let val = 0
            for (const [i, v] of Object.entries(d)) {
              if (i !== 'ageGroup') {
                val += +v
              }
            }
            for (let [i] of Object.entries(d)) {
              if (i !== 'ageGroup') {
                if (val > 0) {
                  d[i] = d[i] / val
                } else {
                  d[i] = 1 / (d.length - 1)
                }
              }
            }
            return d
          })
          d.d = data
          d.o.isRelative = true
        } else {
          d.o.isRelative = false
        }
      }
      d.o.summaryKey = 'всего'
      return d
    },
  },
}
</script>
