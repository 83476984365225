<template>
  <div class="stacked-bar-with-sum" :style="containerWidth">
    <SvgSizeTemplate :width="width" :height="height" :margin="margin" />
    <ChartLegend
      :items="legendItems"
      :options="legendOptions"
      :active="activeDataset"
      @input="datasetChanged"
    />
  </div>
</template>

<script>
import {
  select,
  format as d3format,
  stack as d3stack,
  event as d3event,
  stackOrderNone,
  stackOffsetNone,
  scaleOrdinal,
  quantize,
  interpolateViridis,
  scaleLinear,
  axisBottom,
  axisLeft,
  max,
} from 'd3'
import { mapActions } from 'vuex'

import ChartLegend from './ChartLegend'
import SvgSizeTemplate from './SvgSizeTemplate'
import d3PlotMixin from './d3PlotMixin'
import d3WatchAndMountMixin from './d3WatchAndMountMixin'

export default {
  mixins: [d3PlotMixin, d3WatchAndMountMixin],
  components: {
    ChartLegend,
    SvgSizeTemplate,
  },
  data() {
    return {
      legend: [],
      legendOptions: {
        colCount: 2,
        opacity: 1,
      },
      margin: {
        top: 20,
        right: 20,
        bottom: 40,
        left: 40,
      },
      t: 0,
      gap: 10,
      activeDataset: null,
      tooltip: {
        height: 40,
        width: 300,
        show: false,
        title: '',
        text: '',
        top: 0,
        left: 0,
      },
    }
  },
  props: {
    plotData: {
      type: Array,
      default: () => [],
    },
    width: {
      type: Number,
      default: 950,
    },
    height: {
      type: Number,
      default: 650,
    },
    dictionaries: {
      type: Object,
      default: () => {},
    },
    d: {
      type: Object,
      requered: true,
    },
    options: {
      type: Object,
      default: () => ({
        animationDuration: 500,
      }),
    },
  },
  computed: {
    containerWidth() {
      return `width: ${this.width}px`
    },
    keys() {
      /* Получаем массив причин. Исключаем из него возраст. Плохо, что возраст лежит там же */
      return Object.keys(this.d.d[0]).filter(d => d !== this.d.o.key)
    },
    isRelative() {
      return this.d.o.isRelative
    },
    isNotRelative() {
      return !this.d.o.isRelative
    },
    originalData() {
      return this.d.d
    },
    dataForRender() {
      if (this.isNotRelative) return this.originalData

      let data = this.originalData.slice()
      const { keys } = this

      let summary = {}
      keys.forEach(
        key => (summary[key] = data.reduce((s, b) => (!isNaN(b[key]) ? s + b[key] : s), 0))
      )
      let sum = Object.values(summary).reduce((s, d) => s + d, 0)

      for (const i in summary) {
        summary[i] = summary[i] / sum
      }

      summary[this.d.o.key] = this.d.o.summaryKey
      data = data.filter(d => d[this.d.o.key] !== this.d.o.summaryKey)
      data.push(summary)
      return data
    },
    series() {
      const stack = d3stack().keys(this.keys).order(stackOrderNone).offset(stackOffsetNone)
      return stack(this.dataForRender)
    },
    color() {
      const colorScale = this.series.map(s => s.key)
      return scaleOrdinal()
        .domain(colorScale)
        .range(quantize(interpolateViridis, colorScale.length + 1))
    },
    legendItems() {
      if (!this.d.o.legendData?.length) return this.legend
      const { keys, color } = this
      const legend = []
      keys.forEach(d => {
        legend.push({
          id: Number(d),
          name: this.d.o.legendData.find(b => b.id == d)?.name || '',
          color: color(d),
        })
      })
      return legend
    },
    format() {
      return this.isRelative ? d3format('.2~%') : d3format('.3~')
    },
    bandWidth() {
      // Оригинальные данные не содержат столбца итогов. Оставляем место для него
      return (this.mainPlotAreaWidth - this.gap) / (this.originalData.length + 1)
    },
    xScale() {
      const { bandWidth, gap, dataForRender } = this
      const range = [...Array(dataForRender.length)].map((d, i) => bandWidth * (i + 0.5))

      if (this.isRelative) {
        range[range.length - 1] += gap
      }

      return scaleOrdinal()
        .domain(dataForRender.map(d => d.ageGroup))
        .range(range)
    },
    yScale() {
      return scaleLinear()
        .domain([0, max(this.series, d => max(d, d => d[1])) || 1])
        .range([this.mainPlotAreaHeight, 0])
    },
  },
  watch: {
    d: {
      deep: true,
      handler() {
        this.render()
      },
    },
    activeDataset() {
      this.render()
    },
    tooltip: {
      handler() {
        this.setTooltip(this.tooltip)
      },
      deep: true,
    },
  },
  mounted() {
    this.render()
    this.setTooltip(this.tooltip)
  },
  methods: {
    datasetChanged(value) {
      this.activeDataset = value ? Number(value) : null
    },
    getOpacity(key) {
      return this.activeDataset === null || this.activeDataset === key ? 1 : 0.5
    },
    createXAxis() {
      const { xScale, svg } = this
      svg
        .select('g.x-axis-bottom')
        .call(axisBottom(xScale).tickSizeOuter(0))
        .selectAll('text')
        .attr('y', 0)
        .attr('x', -9)
        .attr('dy', '.35em')
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'end')

      svg.select('.x-axis-bottom .domain').remove()
    },
    createYAxis() {
      const { yScale, format, svg, animationDuration, width, margin } = this
      svg
        .select('g.y-axis-left')
        .transition()
        .duration(animationDuration)
        .call(axis => {
          axis.call(axisLeft(yScale).tickFormat(format).tickSizeOuter(0))
          axis
            .selectAll('line')
            .attr('x1', -5)
            .attr('x2', width - margin.left - margin.right + 5)
            .attr('stroke', 'LightGrey')
        })

      svg.select('.y-axis-left .domain').remove()
    },
    enterRects(enter) {
      const { xScale, yScale, bandWidth, animationDuration } = this
      enter
        .append('rect')
        .attr('x', d => xScale(d.data.ageGroup) - bandWidth / 2)
        .attr('width', bandWidth - 1)
        .attr('y', () => yScale(0))
        .call(
          rect =>
            rect
              .transition('enter')
              .duration(animationDuration)
              .attr('y', d => yScale(d[1]))
              .attr('height', d => yScale(d[0]) - yScale(d[1]) || 0)
        )
    },
    updateRects(update) {
      const { animationDuration, yScale } = this
      update
        .transition('update')
        .duration(animationDuration)
        .attr('y', d => yScale(d[1]))
        .attr('height', d => yScale(d[0]) - yScale(d[1]) || 0)
    },
    exitRects(exit) {
      const { animationDuration } = this
      exit
        .transition('exit')
        .duration(animationDuration)
        .attr('height', 0)
        .attr('y', () => this.mainPlotAreaHeight)
        .on('end', () => exit.remove())
    },
    enterGroups(enter) {
      const { color, enterRects, updateRects, exitRects } = this
      enter
        .append('g')
        .classed('group', true)
        .attr('fill', d => color(Number(d.key)))
        .selectAll('rect')
        .data(d => d)
        .join(enterRects, updateRects, exitRects)
    },
    /*
      Дублирования exitRect в updateGroup нужно чтобы
      пропадала колонка «всего» в относительных величинах
      т.к. это обновление, а не удаление группы
    */
    updateGroups(update) {
      const { color, enterRects, updateRects, exitRects, animationDuration } = this
      update
        .call(group =>
          group
            .transition('group-update')
            .duration(animationDuration)
            .attr('fill', d => color(Number(d.key)))
        )
        .selectAll('rect')
        .data(d => d)
        .join(enterRects, updateRects, exitRects)
    },
    exitGroups(exit) {
      const { animationDuration, exitRects } = this
      exit
        .selectAll('rect')
        .call(exitRects)
        .call(() => setTimeout(() => exit.remove(), animationDuration))
    },
    createGroups() {
      const { enterGroups, updateGroups, exitGroups, svg, series } = this
      svg
        .select('g.content')
        .selectAll('g.group')
        .data(series)
        .join(enterGroups, updateGroups, exitGroups)
    },
    render() {
      if (!this.d || !this.d.d || this.d.d.length === 0) return
      this.createXAxis()
      this.createYAxis()
      this.createGroups()
      this.bindEventsToSVG()
    },
    bindEventsToSVG() {
      const { svg } = this
      svg
        .selectAll('g.group')
        .on('mouseover', d => {
          this.setActiveDataset(Number(d.key))
          this.showTooltip(d3event, Number(d.key))
        })
        .on('mouseout', () => {
          this.setActiveDataset(null)
          this.hideTooltip()
        })
    },
    setActiveDataset(value) {
      this.activeDataset = value
    },
    hideTooltip() {
      this.tooltip.show = false
    },
    showTooltip(event, key) {
      const rect = select(event.target)
      const { format, dataForRender } = this
      const tooltip = {}
      tooltip.show = true
      const rectDomProps = event.target.getBoundingClientRect()
      tooltip.top = rectDomProps.top + window.pageYOffset
      tooltip.left = rectDomProps.left + rectDomProps.width / 2 + window.pageXOffset

      const { name } = this.d.o.legendData.find(d => d.id === key)
      const { ageGroup } = rect.data()[0].data
      const value = format(dataForRender.find(d => d.ageGroup === ageGroup)[key])

      tooltip.title = name
      tooltip.text = `Возраст: ${ageGroup}, значение: ${value}`

      this.tooltip = tooltip
    },
    ...mapActions({ setTooltip: 'tt/setTooltip' }),
  },
}
</script>
