<template>
  <div>
    <TopMenu />

    <ParamsPanel :widthOpen="470" alwaysScroll>
      <SelectRegion />
      <SelectSex />
      <SelectPopulationGroup />
      <SelectYear />
      <SelectReasons />
    </ParamsPanel>

    <MainContent>
      <PageHeader title="Смертность по возрастным группам" />
      <ByAgeContent />
    </MainContent>
  </div>
</template>

<script>
import TopMenu from '../components/layout/TopMenu'
import ParamsPanel from '../components/layout/ParamsPanel'
import MainContent from '../components/layout/MainContent'
import ByAgeContent from '../components/content/ByAgeContent'
import PageHeader from '../components/layout/PageHeader'

import SelectYear from '../components/selects/SelectYear'
import SelectRegion from '../components/selects/SelectRegion'
import SelectSex from '../components/selects/SelectSex'
import SelectPopulationGroup from '../components/selects/SelectPopulationGroup'
import SelectReasons from '../components/selects/SelectReasons'

export default {
  name: 'ByAge',
  components: {
    TopMenu,
    ParamsPanel,
    MainContent,
    ByAgeContent,
    SelectYear,
    SelectRegion,
    SelectSex,
    SelectPopulationGroup,
    SelectReasons,
    PageHeader,
  },
}
</script>
